import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Studio Ghibli`}</h2>
    <p>{`I found this list on FB somewhere. It was a copy/paste type of thing so I'm not referencing the original comment.`}</p>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Princess Mononoke`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Whisper of the Heart`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Kiki's Delivery Service`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Spirited Away`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Howl's Moving Castle`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Secret World of Arrietty`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Grave of the Fireflies`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Pom Poko`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`My Neighbor Totoro`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Wind Rises`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Nausicaa of the Valley of the Wind`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`From Up On Poppy Hill`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Porco Rosso`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Ponyo`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`When Marnie Was There`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Only Yesterday`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Castle in the Sky`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Cat Returns`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`My Neighbors the Yamadas`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Tales of Earthsea`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Ocean waves`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`The Tale of the Princess Kaguya`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Earwig and the Witch`}</li>
    </ul>
    <p>{`Bonus:`}</p>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Panda! Go, Panda!`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`On Your Mark`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Sherlock Hound`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`The Last Unicorn`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`The Red Turtle`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": true,
          "disabled": true
        }}></input>{` `}{`Lupin III: The Castle of Cagliostro`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Mei and the Kittenbus`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Horus: Prince of the Sun`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}{`Ronja, the Robber's Daughter`}</li>
    </ul>
    <p>{`And I found a `}<a parentName="p" {...{
        "href": "https://toofrenchtofunction.tumblr.com/post/149620329244/studio-ghibli-drinking-game-theres-a-cat-or"
      }}>{`drinking game`}</a>{` on Tumblr if that's your thing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      